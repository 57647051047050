import { DropdownProps } from "presentation/model/DropdownProps";

export const comboBoxEvaluatorOption: DropdownProps[] = [({
    dropdownLabel: "EQUAL",
    tagLabel: "EQUAL",
    value: "EQUAL",
}),({
    dropdownLabel: "NOT_EQUAL",
    tagLabel: "NOT_EQUAL",
    value: "NOT_EQUAL",
}),({
    dropdownLabel: "IN",
    tagLabel: "IN",
    value: "IN",
}),({
    dropdownLabel: "NOT_IN",
    tagLabel: "NOT_IN",
    value: "NOT_IN",
}),({
    dropdownLabel: "GREATER_EQUAL",
    tagLabel: "GREATER_EQUAL",
    value: "GREATER_EQUAL",
}),({
    dropdownLabel: "SMALLER_EQUAL",
    tagLabel: "SMALLER_EQUAL",
    value: "SMALLER_EQUAL",
}),({
    dropdownLabel: "GREATER",
    tagLabel: "GREATER",
    value: "GREATER",
}),({
    dropdownLabel: "SMALLER",
    tagLabel: "SMALLER",
    value: "SMALLER",
})];

export const comboBoxEvaluatorForStringOption: DropdownProps[] = [({
    dropdownLabel: "EQUAL",
    tagLabel: "EQUAL",
    value: "EQUAL",
}),({
    dropdownLabel: "NOT_EQUAL",
    tagLabel: "NOT_EQUAL",
    value: "NOT_EQUAL",
}),({
    dropdownLabel: "IN",
    tagLabel: "IN",
    value: "IN",
}),({
    dropdownLabel: "NOT_IN",
    tagLabel: "NOT_IN",
    value: "NOT_IN",
})];

export const comboBoxTriggeredByIndOption: DropdownProps[] = [({
    dropdownLabel: "CONFIRMATION",
    tagLabel: "CONFIRMATION",
    value: "CONFIRMATION",
}),({
    dropdownLabel: "TOS DOWNLOAD",
    tagLabel: "TOS DOWNLOAD",
    value: "TOS DOWNLOAD",
})];
