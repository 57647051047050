export enum MasterDataType {
    BILL_TYPE_MAPPING_TAR_GROUP_SEQ = "BillingTypeMappingTafGroupIdAndTafSeqEntity",
    CHARGE_IND = "ChargeIndEntity",
    COMPANY_TYPE = "CompanyTypeEntity",
    CONSORTIUM = "ConsortiumEntity",
    CNTR_STATUS = "ContainerStatusEntity",
    COUNTRY = "CountryEntity",
    DUMMY_CHG_ON_BILL_TO = "DummyChargeOnBillToEntity",
    FORWARDER_CODE = "ForwarderCodeEntity",
    GEO_IND = "GeographicalIndEntity",
    INTER_COMPANY = "InterCompanyEntity",
    MARSHAL = "MarshalEntity",
    MODALITY = "ModalityEntity",
    HANDLING_CODE = "PhysicalHandlingCodeEntity",
    PROVINCE = "ProvinceEntity",
    SERVICE = "ServiceEntity",
    SOA = "SoaEntity",
    TAR_NATURE = "TariffNatureEntity",
    TERMINAL = "TerminalEntity",
    TS_LEG_CHG_IND = "TranshipmentLegChargeIndEntity",
    SPECIAL_IND = "SpecialIndEntity",
}
export const MasterCodeAlias = [
    "Charge Ind",
    "Special Ind", // 
    "Consortium Code",
    "Service Code",
    "Country Code",
    "Geographical Ind",
    "Charge Code",
    "Marshalling Code",
    "Modality Code",
]

export interface MasterDataEntity {
    id: number,
    code: string,
    refCode?: string,
    desc?: string | null,
    masterType: string,
    versionStamp: number | null,

    [key: string]: string | number | null | undefined
}

export const EMPTY_MASTER_DATA_ENTITY: MasterDataEntity = {
    id: 0,
    code: "",
    refCode: "",
    desc: null,
    masterType: "",
    versionStamp: 0,
}


export interface CodeMaintenanceEntity {
    id: number,
    masterCode: string,
    masterDesc?: string | null,
    masterType: string,
    activeInd: string | null,
    ucInd?: string | null,
    chargeInd?: string | null,

    [key: string]: string | number | null | undefined
}

export const EMPTY_CODE_MAINTENANCE_ENTITY: CodeMaintenanceEntity = {
    id: 0,
    masterCode: "",
    masterType: "",
    activeInd: "Y",
    ucInd: "Y",
    chargeInd: ""
}

