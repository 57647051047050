import _ from "lodash";
import { FC, memo, useCallback, useState } from "react";
import { RightClickMenu } from "veronica-ui-component/dist/component/core";
import { applyMoveAfter, applyMoveConfirm, generateMenuItems } from "./RightClickMenuAssist";

export interface MenuItem {
    command?: () => void,
    label: string,
    icon?: string,
    items?: MenuItem[],
    disabled?: boolean,
}

const RightClickMenuWithMoveAfter: FC<{
    showConfirmMove: boolean,
    rightClickRef: any,
    menuItems?: MenuItem[],
    selectedRows: any[],
    allRows: any[],
    onSaveMove: (rows: any[]) => void,
    onCancel: () => void,
    onRefreshRow: (rows: any[]) => void,
    disabled?: boolean,
    priorityName?: string,
}> = ({
    showConfirmMove = false,
    menuItems = [],
    selectedRows = [],
    allRows = [],
    disabled = false,
    onSaveMove = (rows: any[]) => { },
    onCancel = () => { },
    onRefreshRow = (rows: any[]) => { },
    rightClickRef,
    priorityName
}): any => {
        const [cutRows, setCutRows] = useState<any[]>([]);
        // const allRowsRef = useRef(allRows);
        // const selectedRowsRef = useRef(selectedRows);
        // const showMoveAfterRef = useRef(showConfirmMove);

        // useEffect(() => {
        //     allRowsRef.current = allRows;
        //     selectedRowsRef.current = selectedRows;
        //     showMoveAfterRef.current = showConfirmMove;
        // }, [allRows, selectedRows, showConfirmMove]);

        const onMoveAfter = useCallback(() => {
            if (selectedRows && allRows) {
                setCutRows(selectedRows);
                const newAllRows = applyMoveAfter(selectedRows, allRows, "index");
                onRefreshRow(newAllRows);
            }
        }, [allRows, onRefreshRow, selectedRows]);

        const onMoveConfirm = useCallback(() => {
            if (!_.isEmpty(selectedRows) && allRows && cutRows) {
                const newAllRows = applyMoveConfirm(cutRows, selectedRows[0], allRows, "index", priorityName);
                onSaveMove(newAllRows);
                // onRefreshRow(newAllRows);
                setCutRows([]);
            }
        }, [allRows, cutRows, onSaveMove, priorityName, selectedRows]);

        const generateAllMenuItems = useCallback(() => {
            return generateMenuItems(selectedRows, showConfirmMove, menuItems, onMoveConfirm, onCancel, onMoveAfter);
        }, [menuItems, onCancel, onMoveAfter, onMoveConfirm, selectedRows, showConfirmMove]);

        return <RightClickMenu element={rightClickRef} items={generateAllMenuItems()} disabled={disabled} />;
    };

export default memo(RightClickMenuWithMoveAfter);