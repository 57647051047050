import { useCriteriaTracked } from "presentation/store/Criteria/CriteriaProvider";
import CriteriaEditPanel from "./CriteriaEditPanel";
import CriteriaMaintenance from "./CriteriaMaintenance";
import { useCriteriaVM } from "presentation/hook/Criteria/useCriteriaVM"
import { useEffect } from "react";
import { Loader } from "veronica-ui-component/dist/component/core";

interface CriteriaMasterParameterView {  
    entrypoint: string;  
}

export const CriteriaMasterView: React.FC<CriteriaMasterParameterView> = ({entrypoint}) => {
    const [criteriaState] = useCriteriaTracked();
    const { isAdd, isEditable } = criteriaState.masterState;
    const criteriaVM = useCriteriaVM();

    useEffect(() => {
        criteriaVM.onSetEntrypoint(entrypoint);
    }, [criteriaVM, entrypoint])

    if (criteriaState.isLoading) return <Loader Indicator="Stripe" size="Large" />;
    return <>
        <div className="main-comp-wrapper">
            {(!isAdd && !isEditable) && <CriteriaMaintenance/>}
            {(isAdd || isEditable) && <CriteriaEditPanel/>}
        </div>
    </>
}