export const CriteriaExcludedPolicy = ({
    chargeCriteria : {
        COSCNTRSTATUS_1:"cosCntrStatus_1",
        COSCNTRSTATUS_2:"cosCntrStatus_2",
        COSCNTRSTATUS_3:"cosCntrStatus_3",
        COSCNTRSTATUS_4:"cosCntrStatus_4",
    },

    allCriteria : {
        EQUIPMENTCODEIND_1 : "equipmentCodeInd_1",
        EQUIPMENTCODEIND_2 : "equipmentCodeInd_2",
        CHARGEONCOMPANY_1 : "chargeOnCompany_1",
        CHARGEONCOMPANY_2 : "chargeOnCompany_2",
        CHARGEONCOMPANYGROUP_1 : "chargeOnCompanyGroup_1",
        CHARGEONCOMPANYGROUP_2 : "chargeOnCompanyGroup_2",
        CONSORTIUMGROUP_1 : "consortiumGroup_1",
        CONSORTIUMGROUP_2 : "consortiumGroup_2",
        FDIND_1 : "fdInd_1",
        FDIND_2 : "fdInd_2",
        FDCOUNTRY_1 : "fdCountry_1",
        FDCOUNTRY_2 : "fdCountry_2",
        REFERTEMP_1 : "referTemp_1",
        REFERTEMP_2 : "referTemp_2",
        PODIND_1 : "podInd_1",
        PODIND_2 : "podInd_2",
        PODCOUNTRY_1 : "podCountry_1",
        PODCOUNTRY_2 : "podCountry_2",
        POLIND_1 : "polInd_1",
        POLIND_2 : "polInd_2",
        POLCOUNTRY_1 : "polCountry_1",
        POLCOUNTRY_2 : "polCountry_2",
        POOIND_1 : "pooInd_1",
        POOIND_2 : "pooInd_2",
        POOCOUNTRY_1 : "pooCountry_1",
        POOCOUNTRY_2 : "pooCountry_2",
        SHIPMENTTYPE_1 : "shipmentType_1",
        SHIPMENTTYPE_2 : "shipmentType_2",
        SPECIALIND_1 : "specialInd_1",
        SPECIALIND_2 : "specialInd_2",
    },

    TRIGGEREDBYIND:"TOS DOWNLOAD",
})