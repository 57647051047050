import { ConstraintEntity } from "./ConstraintEntity"

export interface ConstraintGroupEntity {
    id: number | null,
    criteriaId: number | null, 
    constraintGroupConnective: string | null,  
    constraintGroupDefId: number | null, 
    constraintGroupDefName: string  
    parentConstraintGroupId: number | null, 
    constraintGroupName: string, 
    constraints: ConstraintEntity[],

    [key: string]: string | boolean | number | null | undefined | Object
}

export const EMPTY_CRITERIA_CONSGRP_ENTITY : ConstraintGroupEntity = {
    id: null,
    criteriaId: null,
    constraintGroupConnective: "AND",
    constraintGroupDefId:null,
    constraintGroupDefName: "",  
    parentConstraintGroupId: null,
    constraintGroupName: "",
    constraints: [],
}