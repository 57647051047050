
import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { ConstraintGroupDefEntity } from "domain/entity/Criteria/ConstraintGroupDefEntity";
import { ConstraintGroupEntity } from "domain/entity/Criteria/ConstraintGroupEntity";
import { CriteriaEntity } from "domain/entity/Criteria/CriteriaEntity";
import { CriteriaRequestEntity } from "domain/entity/Criteria/CriteriaRequestEntity";
import _ from "lodash";
import { axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import chargeAxiosInstance from "../axios/chargeAxiosInstance";
import { CriteriaRepository } from "./CriteriaRepo";

export const CriteriaRepoImpl = (): CriteriaRepository => {

    const url = '/v1/criteriaComponent';
    //const chargeInd = '/v1/criteriaComponent/chargeInd';
    const consGrp = '/v1/criteriaComponent/consGrp';
    const consGrpList = '/v1/criteriaComponent/consGrpList';

    const getCriteriaByCriteriaTypeName = async (criteriaTypeName: string): Promise<CriteriaEntity[]> => {
        return axiosPostData(chargeAxiosInstance, `${url}/${criteriaTypeName}`, []).then(res => {
            //return res.data;
            return _.sortBy(res.data, ["priority"]);
        }).catch(err => {
            return [];
        });
    }

    const onApply = async (request: CriteriaRequestEntity): Promise<ResponseEntity> => {
        const applyUrl = "/v1/criteriaApply"
        return axiosPostData(chargeAxiosInstance, `${applyUrl}`, request).then(res => {
            return res;
        }).catch(err => {
            return null;
        });
    }

    const getConsGrpByCriteriaId = async (criteriaId: number): Promise<ConstraintGroupEntity[]> => {
        return axiosPostData(chargeAxiosInstance, `${consGrp}/${criteriaId}`, []).then(res => {
            return res.data;
        }).catch(err => {
            return [];
        });
    }

    const getConsGrpByCriteriaName = async (request: CriteriaRequestEntity): Promise<ConstraintGroupDefEntity[]> => {
        return axiosPostData(chargeAxiosInstance, `${consGrpList}`, request).then(res => {
            return res.data;
        }).catch(err => {
            return [];
        });
    }

    const createNewCriteria = async (newData: CriteriaEntity): Promise<ResponseEntity> => {
        return await axiosPostData(chargeAxiosInstance, `${url}`, newData)
    }

    const updateCriteria = async (updatedData: CriteriaEntity): Promise<ResponseEntity> => {
        return await axiosPutData(chargeAxiosInstance, `${url}`, updatedData)
    }

    const addAndUpdateConstraintGroup = async (data: ConstraintGroupEntity[]): Promise<ResponseEntity> => {
        return await axiosPutData(chargeAxiosInstance, `${url}/constraintGroup`, data)
    }

    const getConsGroupDefValue = async (key: string): Promise<string[]> => {
        return axiosPostData(chargeAxiosInstance, `${url}/consGroupDefValue/${key}`, []).then(res => {
            return res.data;
        }).catch(err => {
            return [];
        });
    }

    return {
        getCriteriaByCriteriaTypeName: getCriteriaByCriteriaTypeName,
        onApply: onApply,
        getConsGrpByCriteriaId: getConsGrpByCriteriaId,
        getConsGrpByCriteriaName: getConsGrpByCriteriaName,
        createNewCriteria: createNewCriteria,
        updateCriteria: updateCriteria,
        addAndUpdateConstraintGroup: addAndUpdateConstraintGroup,
        getConsGroupDefValue: getConsGroupDefValue,
    }
}