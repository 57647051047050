
import { CriteriaConstant } from "presentation/constant/Criteria/CriteriaConstant";
import { useCriteriaTracked } from "presentation/store/Criteria/CriteriaProvider";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { memo } from "react";
import { Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const CriteriaTitleBar:React.FC = () => {
    const [criteriaState] = useCriteriaTracked();  
    const CRITERIA_CONSTANT = CriteriaConstant.Title;

    return <Sidebarheader style={{width: '100%', display:"flex", alignItems:"center"}}>
        <HeaderTitle>
            {criteriaState.entrypoint==='CHARGE' && CRITERIA_CONSTANT.CHARGE_CRITERIA_MAIN}
            {criteriaState.entrypoint==='GCGEN' && CRITERIA_CONSTANT.GENERAL_CHARGE_CRITERIA_MAIN}
            {criteriaState.entrypoint==='CHGEXL' && CRITERIA_CONSTANT.CHARGE_EXCLUSION_CRITERIA_MAIN}
            {criteriaState.entrypoint==='DOCSEP' && CRITERIA_CONSTANT.DOCUMENT_SEPARATION_CRITERIA_MAIN}
            {criteriaState.entrypoint==='DOCGROUP' && CRITERIA_CONSTANT.DOCUMENT_GROUPING_CRITERIA_MAIN}
        </HeaderTitle>
    </Sidebarheader>
}

export default memo(CriteriaTitleBar);