import { ConstraintDefEntity, EMPTY_CRITERIA_CONSDEF_ENTITY } from "./ConstraintDefEntity"
import { ConstraintValueEntity } from "./ConstraintValueEntity"

export interface ConstraintEntity {
    id: number | null,
    constraintGroupId: number | null, 
    constraintEvaluator: string | null,
    constraintDefId: number | null, 
    constraintValues: ConstraintValueEntity[]
    constraintDef: ConstraintDefEntity,

    [key: string]: string | boolean | number | null | undefined | Object
}

export const EMPTY_CRITERIA_CONS_ENTITY : ConstraintEntity = {
    id: null,
    constraintGroupId: null,
    constraintEvaluator: null,
    constraintDefId: null,
    constraintValues: [],
    constraintDef:EMPTY_CRITERIA_CONSDEF_ENTITY,
}