import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { CodeMaintenanceEntity, MasterDataEntity } from "domain/entity/MasterData/MasterDataEntity";
import _ from "lodash";
import { axiosGetData, axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { MasterDataRepository } from "./MasterDataRepo";

export const MasterDataRepoImpl = () : MasterDataRepository => {
    const url = "/v1/masterdata"

    /**
     * Get the master data list by master type
     * @param masterType 
     * @returns MasterDataEntity[]
     */
    const getMasterDataByKey = async(masterType: string) : Promise<MasterDataEntity[]> => {
        return await axiosGetData(referenceAxiosInstance, `${url}/type`, [masterType]).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    /**
     * Get the master data for code maintenance only
     * @returns MasterDataEntity[]
     */
    const getMasterDataForCodeMaintenance = async() : Promise<CodeMaintenanceEntity[]> => {
        return await axiosGetData(referenceAxiosInstance, `/v1/masterdataformaintenance`, []).then(res => {
            const data = res.data;
            return _.sortBy(data,["masterType","masterCode"]);
        }).catch(err => {
            return [];
        });
    }

    /**
     * Create new master data
     * @param newMasterData 
     * @returns ResponseEntity
     */
    const saveNewMasterData = async(newMasterData: CodeMaintenanceEntity) : Promise<ResponseEntity> => {
        return await axiosPostData(referenceAxiosInstance, `${url}`, newMasterData);
    }

    /**
     * Update master data
     * @param replacedMasterData 
     * @returns ResponseEntity
     */
    const replaceMasterData = async(replacedMasterData: CodeMaintenanceEntity) : Promise<ResponseEntity> => {
        return await axiosPutData(referenceAxiosInstance, `${url}`, replacedMasterData);
    }

    return {
        getMasterDataByKey: getMasterDataByKey,
        getMasterDataForCodeMaintenance: getMasterDataForCodeMaintenance,
        saveNewMasterData: saveNewMasterData,
        replaceMasterData: replaceMasterData,
    }
}