/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { convertDateToDateForComponent, convertDateToLocal } from "presentation/utils/timeUtil";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { CommonField, DatePicker, FieldType } from "veronica-ui-component/dist/component/core";

interface ComponentProps {
    label?:string,
    date: Date | null | string | undefined,
    onDateChange: (fieldName:string, date: any, fFullValue?: any) => void,
    onClose?: () => void,
    width?:string,
    errorMessage?: string,
    fieldName?: string,
    disabled?: boolean,
    optional?: boolean,
}

const DatePickerComponent:React.FC<ComponentProps> = (props) => {
    const componentRef = useRef<HTMLDivElement>(null);
    
    const [dateInternal, setDateInternal] = useState<any>(props.date?convertDateToLocal(moment(props.date)):null);

    const onDatesChange = useCallback((date: any) => {
        setDateInternal(date?moment(date):null);
        props.onDateChange(props.fieldName??"", date);
    }, [dateInternal]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const isNotClickingDateRangePicker = componentRef.current && !componentRef.current.contains(event.target as Node)
            if (isNotClickingDateRangePicker) {
                // Clicked outside the component
                if (props.onClose) {
                    props.onClose()
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setDateInternal(props.date?convertDateToLocal(moment(props.date)):null);
    }, [props.date]);


    return <div ref={componentRef}>
        {(!props.disabled) ?
        <DatePicker
            label={props.label?props.label:''}
            width={props.width?props.width:'300px'}
            // value={!props.date && !dateInternal && !dateInternal?.isValid() ? ""
            //     : (dateInternal && dateInternal?.isValid() ? dateInternal?.toDate()
            //       : (props.date? convertDateToLocal(moment(props.date))?.toDate() : ""))}
            value={(props.date? convertDateToLocal(moment(props.date))?.toDate() : "")}
            errorMessage={props.errorMessage}   
            optional={props.optional}         
            onChange={(e) => {
                if (e) {
                    const start = e;
                    onDatesChange(start);
                }
            }} /> :
            <CommonField
                isReadOnly={true}
                readOnlyValue={dateInternal && dateInternal.isValid() ? 
                    convertDateToDateForComponent(dateInternal?.toDate()) :
                    (props.date ?
                        convertDateToDateForComponent(moment(props.date)) :
                        ""
                    )}
                fieldLabel={props.label?props.label:''}
                fieldType={FieldType.TEXT}
                fieldKey={''}                
                requiredFieldList={[]}
                onFieldChange={() => {}} />
        }
    </div>

}

export default memo(DatePickerComponent);