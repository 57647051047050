import * as yup from 'yup';


export const commonChargeCriteriaValidation: { [x: string]: any; } = {
  criteriaName: yup.string().required("Missing"),
  chargeInd: yup.string().required("Missing"),
  effectiveDate: yup.string().required("Missing"),
  activeInd: yup.string().required("Missing"),
};

export const commonGenCriteriaValidation: { [x: string]: any; } = {
  criteriaName: yup.string().required("Missing"),
  chargeType: yup.string().required("Missing"),
  triggeredBy: yup.string().required("Missing"),
  effectiveDate: yup.string().required("Missing"),
  activeInd: yup.string().required("Missing"),
};

export const commonExclCriteriaValidation: { [x: string]: any; } = {
  criteriaName: yup.string().required("Missing"),
  effectiveDate: yup.string().required("Missing"),
  activeInd: yup.string().required("Missing"),
};

export const commonGroupListValidation: { [x: string]: any; } = {
  constraintGroupName: yup.string().required("Missing"),
  constraints: yup.array().of(
    yup.object().shape({
      constraintEvaluator: yup.string().required("Missing"),
      constraintDef: yup.object().shape({
        constraintDefDisplayName: yup.string().required("Missing"),
      }),
      constraintValues: yup.array().min(1, "Missing"),
    })
  ),
};

export const chargeCriteriaValidation: { [x: string]: any; } = {
  ...commonChargeCriteriaValidation,
};

export const genCriteriaValidation: { [x: string]: any; } = {
  ...commonGenCriteriaValidation,
};

export const exclCriteriaValidation: { [x: string]: any; } = {
  ...commonExclCriteriaValidation,
};

export const groupListValidation: { [x: string]: any; } = {
  ...commonGroupListValidation,
};


export const chargeCriteriaValidationSchema = yup.object().shape(chargeCriteriaValidation);
export const genCriteriaValidationSchema = yup.object().shape(genCriteriaValidation);
export const exclCriteriaValidationSchema = yup.object().shape(exclCriteriaValidation);
export const groupListValidationSchema = yup.object().shape(groupListValidation);
