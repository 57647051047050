import { EMPTY_TARIFF_CODE_MODEL } from "presentation/model/Criteria/CriteriaModel";
import { useState } from "react";
import { createContainer } from "react-tracked";

const {
    Provider: CriteriaProvider,
    useTracked: useCriteriaTracked
} = createContainer(() => useState(EMPTY_TARIFF_CODE_MODEL), {concurrentMode: true});
export { CriteriaProvider, useCriteriaTracked };

