import { ConstraintEntity } from "domain/entity/Criteria/ConstraintEntity";
import { ConstraintValueEntity } from "domain/entity/Criteria/ConstraintValueEntity";
import _ from "lodash";
import { comboBoxEvaluatorForStringOption, comboBoxEvaluatorOption } from "presentation/constant/CriteriaDropdownProps/CriteriaDropdownPropsOptions";
import { useCriteriaVM } from "presentation/hook/Criteria/useCriteriaVM";
import { CriteriaModel } from "presentation/model/Criteria/CriteriaModel";
import { DropdownProps } from "presentation/model/DropdownProps";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { memo, useCallback, useEffect, useState } from "react";
import { FieldType, IconButton, IFieldValue } from "veronica-ui-component/dist/component/core";

const ConstraintItemPanel = ({
    panelKey,
    constraintItem,
    criteriaState,
    onDeleteConstraintItem,
    onUpdateConstraintItem,
    itemBlur,
    onResetScoll,
}: {
    panelKey: string
    constraintItem: ConstraintEntity,
    criteriaState: CriteriaModel,
    onDeleteConstraintItem: (removeItem: ConstraintEntity) => void,
    onUpdateConstraintItem: (updateItem: ConstraintEntity) => void,
    itemBlur: boolean
    onResetScoll: () => void,
}) => {
    const [currentConstraintDef, setCurrentConstraintDef] = useState(constraintItem.constraintDef);
    const [currentConstraintEvaluator, setCurrentConstraintEvaluator] = useState(constraintItem.constraintEvaluator);
    const [constraintValues, setConstraintValues] = useState(constraintItem.constraintValues);
    const { allFormState, isRead, isSaveClicked, currentSelectItem } = criteriaState.componentState;
    const [comboBoxValueOption, setComboBoxValueOption] = useState<DropdownProps[]>();
    const [comboBoxDefOption, setComboBoxDefOption] = useState<DropdownProps[]>();
    const [isMultipleValue, setIsMultipleValue] = useState(true);
    const criteriaVM = useCriteriaVM();
    const [panelZIndexCriteria, setPanelZIndexCriteria] = useState<{}>({ width: "20vw" });
    const [panelZIndexSymbol, setPanelZIndexSymbol] = useState<{}>({ width: "9vw" });
    const [panelZIndexValue, setPanelZIndexValue] = useState<{}>({ width: "19.5vw" });
    const [showDummyDivCriteria, setShowDummyDivCriteria] = useState<boolean>(false);
    const [showDummyDivSymbol, setShowDummyDivSymbol] = useState<boolean>(false);
    const [showDummyDivValue, setShowDummyDivValue] = useState<boolean>(false);
    const [isRefresh, setIsRefresh] = useState<boolean>(false);

    useEffect(() => {
        const fetchValueData = async () => {
            if (constraintItem.constraintDef?.constraintDefDisplayName) {
                const valueData = await criteriaVM.getConsGroupDefValue(constraintItem.constraintDef.constraintDefDisplayName + ':' + constraintItem.constraintDef.constraintDefName);
                setComboBoxValueOption(valueData);
            }
        };
        fetchValueData();
    }, [constraintItem.constraintDef, criteriaState.dynamicOptions.consValueDropdownOptions, criteriaVM]);

    useEffect(() => {
        const cons = currentSelectItem.constraints;
        let comBoxDef: DropdownProps[];
        if (currentSelectItem.constraintGroupDefName) {
            comBoxDef = criteriaState.dynamicOptions.consDefDropdownOptions[currentSelectItem.constraintGroupDefName];
        } else {
            const newOptions = criteriaState.consGrpsTypeDropdownOptions[0];
            comBoxDef = criteriaState.dynamicOptions.consDefDropdownOptions[newOptions?.key ?? '']
        }
        comBoxDef = comBoxDef.filter(item => !cons.some(con => con.constraintDef.constraintDefDisplayName === item.value));
        setComboBoxDefOption(comBoxDef);
    }, [currentSelectItem.constraintGroupDefName, criteriaState.dynamicOptions.consDefDropdownOptions, criteriaState.consGrpsTypeDropdownOptions, currentSelectItem.constraints]);

    useEffect(() => {
        const cons = currentSelectItem.constraints;
        const conmBox = criteriaState.dynamicOptions.consDefDropdownOptions[currentSelectItem.constraintGroupDefName]
        if (comboBoxDefOption && cons) {
            setComboBoxDefOption(conmBox.filter((item) =>
                !cons.some((con) => con.constraintDef.constraintDefDisplayName === item.value) ||
                item.value === currentConstraintDef.constraintDefDisplayName
            ))
        }
    }, [isRefresh, currentSelectItem.constraints, currentSelectItem.constraintGroupDefName, criteriaState.dynamicOptions.consDefDropdownOptions, comboBoxDefOption, currentConstraintDef.constraintDefDisplayName]);

    const onConstraintDefChange = useCallback(async (fieldKey: string, fieldValue: IFieldValue) => {
        const fieldValueStr = fieldValue?.toString();
        const selectedDef = criteriaState.allConstraintDef.find((def) => def.constraintDefDisplayName === fieldValueStr);

        if (selectedDef) {
            setCurrentConstraintDef(selectedDef);
            let consValueList: ConstraintValueEntity[] = [];
            setConstraintValues(consValueList);
            onUpdateConstraintItem({ ...constraintItem, constraintDef: selectedDef, constraintDefId: selectedDef.id, constraintValues: consValueList })
            //criteriaVM.getConsGroupDefValue(selectedDef.constraintDefDisplayName+':'+selectedDef.constraintDefName);
        }
    }, [constraintItem, criteriaState.allConstraintDef, onUpdateConstraintItem]);

    const onConstraintEvaluatorChange = useCallback(async (fieldKey: string, fieldValue: IFieldValue) => {
        const fieldValueStr = fieldValue?.toString() ?? null;
        let constraintValues: ConstraintValueEntity[] = constraintItem.constraintValues;
        setCurrentConstraintEvaluator(fieldValueStr);
        if (fieldValueStr === "IN" || fieldValueStr === "NOT_IN") {
            setIsMultipleValue(true);
            constraintValues = constraintItem.constraintValues.filter(item => item.constraintValue !== "");
        } else {
            setIsMultipleValue(false);
        }
        setConstraintValues(constraintValues);
        onUpdateConstraintItem({ ...constraintItem, constraintEvaluator: fieldValueStr, constraintValues: constraintValues })
    }, [constraintItem, onUpdateConstraintItem]);

    const onConstraintValuesChange = useCallback(async (fieldName: string, fieldValue: IFieldValue) => {
        let val = fieldValue;
        let consValueList: ConstraintValueEntity[] = [];
        if (val) {
            if (_.isArray(val)) {
                val = _.uniq(val?.map((item: any) => item.value || item.key));

                consValueList = val.map(value => ({
                    id: null,
                    constraintId: constraintItem.id,
                    constraintValue: value
                }));
            } else {
                consValueList = [{
                    id: null,
                    constraintId: constraintItem.id,
                    constraintValue: val?.toString() ?? ""
                }]
            }
        } else {
            consValueList = []
        }

        setConstraintValues(consValueList);
        onUpdateConstraintItem({ ...constraintItem, constraintValues: consValueList })
    }, [onUpdateConstraintItem, constraintItem]);

    const calculatePosition = useCallback((key: string) => {
        const pDiv = document.querySelector(`#${key}`);
        if (pDiv) {
            console.log(`Found element #constraintDefDisplayName-${key}`);

            let totalTop = 0;
            let totalLeft = 0;
            let currentElement = pDiv as HTMLElement;

            while (currentElement) {
                console.log(`Processing element: ${currentElement.id || currentElement.className}`, {
                    offsetTop: currentElement.offsetTop,
                    scrollTop: currentElement.scrollTop,
                    offsetLeft: currentElement.offsetLeft,
                    scrollLeft: currentElement.scrollLeft
                });

                totalTop += currentElement.offsetTop;
                totalLeft += currentElement.offsetLeft;

                let parent = currentElement.parentElement;
                while (parent) {
                    if (parent.scrollTop > 0 || parent.scrollLeft > 0) {
                        console.log(`Found scrollable parent: ${parent.id || parent.className}`);
                        totalTop -= parent.scrollTop;
                        totalLeft -= parent.scrollLeft;
                    }
                    parent = parent.parentElement;
                }

                currentElement = currentElement.offsetParent as HTMLElement;
            }

            console.log(`Final position for #constraintDefDisplayName-${key}:`, { left: totalLeft, top: totalTop });

            return {
                left: totalLeft,
                top: totalTop
            };
        } else {
            console.log(`Element #constraintDefDisplayName-${key} not found`);
        }
    }, []);

    const onFocus = useCallback((panelKey: string) => {
        const position = calculatePosition(panelKey);
        if (panelKey.includes('constraintDefDisplayName')) {
            setShowDummyDivCriteria(true);
            setPanelZIndexCriteria({ ...position, width: "20vw", zIndex: '99', overflow: 'visible', position: 'fixed' });
            setIsRefresh(!isRefresh);
        }
        if (panelKey.includes('constraintEvaluator')) {
            setShowDummyDivSymbol(true);
            setPanelZIndexSymbol({ ...position, width: "9vw", zIndex: '99', overflow: 'visible', position: 'fixed' });
        }
        if (panelKey.includes('constraintValue')) {
            setShowDummyDivValue(true);
            setPanelZIndexValue({ ...position, width: "19.5vw", zIndex: '99', overflow: 'visible', position: 'fixed' });
        }
        if (onResetScoll) {
            onResetScoll();
        }
    }, [calculatePosition, onResetScoll, isRefresh])

    const onBlur = useCallback(() => {
        setPanelZIndexCriteria({ width: "20vw" });
        setPanelZIndexSymbol({ width: "9vw" });
        setPanelZIndexValue({ width: "19.5vw" });
        setShowDummyDivCriteria(false);
        setShowDummyDivSymbol(false);
        setShowDummyDivValue(false);
        setIsRefresh(!isRefresh);
    }, [isRefresh]);

    useEffect(() => {
        if (itemBlur) {
            onBlur();
        }
    }, [itemBlur, onBlur])

    const memoCriteria =
        <div id={`constraintDefDisplayName-${panelKey}`} className='im-flex-row-item' style={{ ...panelZIndexCriteria }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                readOnlyValue={currentConstraintDef?.constraintDefDisplayName || ''}
                fieldValue={currentConstraintDef?.constraintDefDisplayName}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'constraintDefDisplayName'}
                options={comboBoxDefOption}
                maxLength={60}
                isShowMissingError={true}
                requiredFieldList={['constraintDefDisplayName']}
                onFieldChange={onConstraintDefChange}
                onFocus={() => onFocus(`constraintDefDisplayName-${panelKey}`)}
                onBlur={onBlur}
            />
        </div >

    const memoSymbol =
        <div id={`constraintEvaluator-${panelKey}`} className='im-flex-row-item' style={{ ...panelZIndexSymbol }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                readOnlyValue={currentConstraintEvaluator || ''}
                fieldValue={currentConstraintEvaluator}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'constraintEvaluator'}
                options={currentConstraintDef?.constraintDefLovRefColumnTypes === 'String' ? comboBoxEvaluatorForStringOption : comboBoxEvaluatorOption}
                maxLength={60}
                isShowMissingError={true}
                requiredFieldList={['constraintEvaluator']}
                onFieldChange={onConstraintEvaluatorChange}
                onFocus={() => onFocus(`constraintEvaluator-${panelKey}`)}
                onBlur={onBlur}
            />
        </div>

    const memoValue =
        <div id={`constraintValue-${panelKey}`} className='im-flex-row-item' style={{ ...panelZIndexValue }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                readOnlyValue={currentConstraintDef?.constraintDefLovQuery ? constraintValues?.map((item) => item?.constraintValue).join(', ') ?? ''
                    : constraintValues[0]?.constraintValue ?? ''}
                fieldValue={currentConstraintDef?.constraintDefLovQuery ? constraintValues?.map((item) => item?.constraintValue)
                    : constraintValues[0]?.constraintValue}
                isSaveClicked={isSaveClicked}
                allValues={constraintValues?.map((item) => item?.constraintValue).join(', ') ?? ''}
                fieldType={currentConstraintDef?.constraintDefLovQuery ? FieldType.DROPDOWN : FieldType.TEXT}
                fieldKey={'constraintValue'}
                options={currentConstraintDef?.constraintDefLovQuery ? comboBoxValueOption : []}
                maxLength={60}
                isShowMissingError={true}
                isMultipleDropDown={isMultipleValue}
                requiredFieldList={['constraintValue']}
                onFieldChange={onConstraintValuesChange}
                onFocus={() => onFocus(`constraintValue-${panelKey}`)}
                onBlur={onBlur}
            />
        </div>

    const memoDeleteIcon =
        <div className='im-flex-row-item' style={{ width: "30px" }}>
            <IconButton fileName="Icon-trash" toolTipText="Delete" disabled={isRead} size="medium" onClick={() => onDeleteConstraintItem(constraintItem)} />
        </div>

    useEffect(() => {
        setCurrentConstraintDef(constraintItem.constraintDef);
        setCurrentConstraintEvaluator(constraintItem.constraintEvaluator);
        if (constraintItem.constraintEvaluator === "IN" || constraintItem.constraintEvaluator === "NOT_IN") {
            setIsMultipleValue(true);
        } else {
            setIsMultipleValue(false);
        }
        setConstraintValues(constraintItem.constraintValues);
    }, [constraintItem, criteriaVM]);

    return (
        <div className='im-flex-row' id={`constraintItem-${panelKey}`}>
            {memoCriteria}
            {showDummyDivCriteria && <div className={"im-flex-row-item"} style={{ width: "20vw" }}></div>}
            {memoSymbol}
            {showDummyDivSymbol && <div className={"im-flex-row-item"} style={{ width: "9vw" }}></div>}
            {memoValue}
            {showDummyDivValue && <div className={"im-flex-row-item"} style={{ width: "19.5vw" }}></div>}
            {memoDeleteIcon}
        </div>
    );
};

export default memo(ConstraintItemPanel);