import { MasterDataRepoImpl } from "domain/repository/MasterData/MasterDataRepoImpl";
import { CriteriaRepoImpl } from "domain/repository/Criteria/CriteriaRepoImpl";
import { useCriteriaTracked } from "presentation/store/Criteria/CriteriaProvider";
import { CriteriaVM } from "presentation/viewModel/Criteria/CriteriaVM";
import { useMemo } from "react";
import { ChargeTypeRepoImpl } from "domain/repository/ChargeType/ChargeTypeRepoImpl";

export const useCriteriaVM = () => {
    const [, setCriteriaState] = useCriteriaTracked();
    const tariffCodeVM = useMemo(() =>
        CriteriaVM({
            dispatch: [setCriteriaState],
            masterDataRepo: MasterDataRepoImpl(),
            criteriaRepo: CriteriaRepoImpl(),
            chargeTypeRepo: ChargeTypeRepoImpl(),
        }), [setCriteriaState])

    return tariffCodeVM
}

