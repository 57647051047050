import { ChargeTypeCriteria } from "domain/entity/ChargeType/ChargeTypeCriteria";
import { ChargeTypeEntity } from "domain/entity/ChargeType/ChargeTypeEntity";
import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import _ from "lodash";
import { axiosDeleteData, axiosGetData, axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { ChargeTypeRepository } from "./ChargeTypeRepo";

export const ChargeTypeRepoImpl = (): ChargeTypeRepository => {
    const url = '/v1/chargeType';

    const getAllChargeTypesForCombobox = async (): Promise<ChargeTypeEntity[]> => {
        return axiosGetData(referenceAxiosInstance, "/v1/chargeTypeSearchForComboBox", []).then(res => {
            const data = res.data;
            return _.sortBy(data, ["key"]);
        }).catch(err => {
            return [];
        })

    }

    const getAllChargeTypes = async (): Promise<ChargeTypeEntity[]> => {
        return axiosGetData(referenceAxiosInstance, url, []).then(res => {
            const data = res.data;
            return _.sortBy(data, ["chargeType", "subChargeType"]);
        }).catch(err => {
            return [];
        })

    }

    const getChargeTypeByKey = async (key: string): Promise<ChargeTypeEntity> => {
        return axiosGetData(referenceAxiosInstance, `${url}/${key}`, []).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return null;
        });
    }

    const getChargeTypeByRole = async (role: string): Promise<ChargeTypeEntity[]> => {
        return axiosGetData(referenceAxiosInstance, `${url}/role/${role}`, []).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return null;
        });
    }

    const createNewChargeType = async (newData: ChargeTypeEntity): Promise<ResponseEntity> => {
        const result = await axiosPostData(referenceAxiosInstance, `${url}`, newData);
        return result;
    }

    const updateChargeType = async (updatedData: ChargeTypeEntity): Promise<ResponseEntity> => {
        const result = await axiosPutData(referenceAxiosInstance, `${url}`, updatedData)
        return result
    }

    const deleteChargeTypeByKey = async (key: string): Promise<boolean> => {
        return await axiosDeleteData(referenceAxiosInstance, `${url}`, key).then(res => {
            return res.status === 200
        }).catch(error => {
            return false
        })
    }

    const getFspChargeType = async (): Promise<ChargeTypeEntity[]> => {
        const newUrl = url + '/getFspChargeType'
        return axiosGetData(referenceAxiosInstance, newUrl, []).then(res => {
            const data = res.data;
            return _.sortBy(data, ["key"]);
        }).catch(err => {
            return [];
        })
    }

    const searchChargeTypeByCriteria = async (criteria: ChargeTypeCriteria): Promise<ChargeTypeEntity[]> => {
        const searchUrl = '/v1/chargeTypeSearch'
        const result = await axiosPostData(referenceAxiosInstance, `${searchUrl}`, criteria).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
        return result;
    }

    return {
        getAllChargeTypesForCombobox: getAllChargeTypesForCombobox,
        getAllChargeTypes: getAllChargeTypes,
        getChargeTypeByKey: getChargeTypeByKey,
        getChargeTypeByRole: getChargeTypeByRole,
        createNewChargeType: createNewChargeType,
        updateChargeType: updateChargeType,
        deleteChargeTypeByKey: deleteChargeTypeByKey,
        getFspChargeType: getFspChargeType,
        searchChargeTypeByCriteria: searchChargeTypeByCriteria,
    }
}