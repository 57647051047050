export interface CriteriaEntity {
    id: number | null,
    criteriaName: string,
    criteriaDesc: string | null,  
    criteriaTypeId: number | null,
    criteriaConnective: string | null,
    effectiveDate:  Date | null,
    expiryDate:  Date | null,
    priority: number | null,
    activeInd: "Y" | "N",
    chargeType: string | null,
    subChargeType: string | null,
    triggeredBy: string | null,
    criteriaType: string | null,
    action: string | null,
    chargeInd: string | null,

    [key: string]: string | boolean | number | null | undefined | Object
}

export const EMPTY_CRITERIA_ENTITY : CriteriaEntity = {
    id: null,
    criteriaName: "",
    criteriaDesc: null,   
    criteriaTypeId: null,
    criteriaConnective: null,
    effectiveDate: null,
    expiryDate: null,
    priority: null,
    activeInd: "Y",
    chargeType: null,
    subChargeType: null,
    triggeredBy: null,
    criteriaType: null,
    action: null,
    chargeInd: null,
}