export interface ConstraintDefEntity {
    id: number | null,
    constraintDefTypeId: number | null,
    constraintDefName: string | null,
    constraintDefDisplayName: string,
    constraintDefLovQuery: string | null,
    constraintDefLovRefColumns: string | null,
    constraintDefLovRefColumnTypes: string | null,
    constraintDefLovList:string[],

    [key: string]: string | boolean | number | null | undefined | Object
}

export const EMPTY_CRITERIA_CONSDEF_ENTITY : ConstraintDefEntity = {
    id: null,
    constraintDefTypeId: null,
    constraintDefName: null,
    constraintDefDisplayName: "",
    constraintDefLovQuery: null,
    constraintDefLovRefColumns: null,
    constraintDefLovRefColumnTypes: null,
    constraintDefLovList:[],
}