import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { CriteriaProvider } from "presentation/store/Criteria/CriteriaProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import { CriteriaMasterView } from "presentation/view/section/Criteria/CriteriaMasterView";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const CriteriaContMain = () => {
    return <ANAInfoWrapper permission={Permission.CHARGE_EXCLMAIN_CRITERIA_MAINTENANCE}>
        <MessageBarWrapper>
            <CriteriaProvider>
                <GridStyles/>
                <CriteriaMasterView entrypoint = {'CHGEXL'}/>
            </CriteriaProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default CriteriaContMain;