import { MenuItem } from "presentation/view/components/OverflowMenuButton";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";

import { ConstraintDefEntity } from "domain/entity/Criteria/ConstraintDefEntity";
import { ConstraintGroupEntity, EMPTY_CRITERIA_CONSGRP_ENTITY } from "domain/entity/Criteria/ConstraintGroupEntity";
import { CriteriaEntity, EMPTY_CRITERIA_ENTITY } from "domain/entity/Criteria/CriteriaEntity";
import { GroupRadioList } from "veronica-ui-component/dist/component/core/GroupRadioButton/GroupRadioButton";

export interface CriteriaDropdownOptions {

    chargeIndDropdownOptions: DropdownProps[],
    chgTypeDropdownOptions: DropdownProps[],
    subChgTypeDropdownOptions: { [key: string]: DropdownProps[] },
    consDefDropdownOptions: { [key: string]: DropdownProps[] },
    consValueDropdownOptions: DropdownProps[],

}
export interface CriteriaChangeState extends BaseViewChangeSate {
    editingCriteriaEntity: CriteriaEntity,
}

export interface CriteriaComponentChangeState extends BaseViewChangeSate {
    consGrpList: ConstraintGroupEntity[],
    currentSelectItem: ConstraintGroupEntity,
    selectedRows: ConstraintGroupEntity[],
}

export interface CriteriaModel {
    isLoading: boolean,
    isLoadingMoveAfter: boolean,
    isShowDetail: boolean,
    isShowCriteriaPanel: boolean,
    criteriaEntityList: CriteriaEntity[],
    currentSelectedRow: CriteriaEntity,
    selectedRows: CriteriaEntity[],
    dynamicOptions: CriteriaDropdownOptions,
    isBackFromDetail: boolean,
    subMenuItemArray: MenuItem[],
    isShowMoveToPanel: boolean,
    masterState: CriteriaChangeState,
    componentState: CriteriaComponentChangeState,
    entrypoint:string,
    chargeTypes?:  string[] | null,
    subChargeTypes?:  string[] | null,
    consGrpsTypeDropdownOptions: GroupRadioList[],
    allConstraintDef:ConstraintDefEntity[],
}

export const EMPTY_TARIFF_CODE_MODEL: CriteriaModel = {
    isLoading: false,
    isLoadingMoveAfter: false,
    isShowDetail: false,
    isShowCriteriaPanel: false,
    criteriaEntityList: [],
    currentSelectedRow: { ...EMPTY_CRITERIA_ENTITY },
    selectedRows: [],
    dynamicOptions: {
        chargeIndDropdownOptions: [],
        chgTypeDropdownOptions: [],
        subChgTypeDropdownOptions: {},
        consDefDropdownOptions: {},
        consValueDropdownOptions: [],
    },
    isBackFromDetail: false,
    subMenuItemArray: [],
    isShowMoveToPanel: false,
    masterState: {
        isSliderOpen: false,
        isTabularDataActive: false,
        isAdd: false,
        isRead: false,
        isEditable: false,
        isSaveClicked: false,
        lastEditRowId: "",
        isSelected: false,
        slider: {
            isAdd: false,
            isRead: false,
            isEditable: false
        },
        editingCriteriaEntity: EMPTY_CRITERIA_ENTITY
    },
    componentState: {
        isSliderOpen: false,
        isTabularDataActive: false,
        isAdd: false,
        isRead: false,
        isEditable: false,
        isSaveClicked: false,
        lastEditRowId: "",
        isSelected: false,
        slider: {
            isAdd: false,
            isRead: false,
            isEditable: false
        },
        consGrpList: [],
        currentSelectItem: EMPTY_CRITERIA_CONSGRP_ENTITY,
        selectedRows: []
    },
    entrypoint:"",
    chargeTypes: [],
    subChargeTypes: [],
    consGrpsTypeDropdownOptions: [],
    allConstraintDef: [],
}